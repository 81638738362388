<template>
  <router-view />
</template>

<style lang="scss">
  #app {
    font-family: Raleway, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    font-size: 16px;
  }
  body {
    margin: 0;
  }
  * {
    font-family: Raleway, sans-serif;
  }
</style>
