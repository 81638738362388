import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/:office_name',
    name: 'mainTvView',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/mainTvView.vue')
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
